<template>
	<el-container style="min-height: 100vh; display: flex; margin: 0 auto; flex-direction: column; justify-content: space-between;">
		<div>
			<h3 style="padding: 15px; color: #b7a06a; background-color: #e1d6ba;">入职登记表</h3>
			<van-form @submit="onSubmit" :model="register">
				<van-field v-model="register.name" name="name" label="姓名" placeholder="请输入真实姓名" :rules="[{ required: true, message: '请输入真实姓名' }]">
					<template #button>
						<van-radio-group v-model="register.sex" direction="horizontal">
							<van-radio name="1">男</van-radio>
							<van-radio name="2">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field v-model="register.email" name="email" label="邮箱" placeholder="请输入电子邮箱" :rules="[{ required: true, message: '请输入电子邮箱' }]"></van-field>
				<van-field v-model="register.mobile" name="mobile" label="手机" placeholder="请输入手机号码。已开通绑定企业微信" :rules="[{ required: true, message: '请输入手机号码' }]"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="民族" placeholder="点击选择民族" @click="showPicker = true"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="政治面貌" placeholder="点击选择政治面貌" @click="showPicker = true"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="最高学历" placeholder="点击选择最高学历" @click="showPicker = true"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="证件类型" placeholder="点击选择证件类型" @click="showPicker = true"></van-field>
				<van-field v-model="register.deposit_bank" name="deposit_bank" label="证件号码" placeholder="请输入证件号码" :rules="[{ required: true, message: '请输入证件号码' }]"></van-field>
				<van-field v-model="register.mobile" name="name" label="开户银行" placeholder="请输入开户银行" :rules="[{ required: true, message: '请输入开户银行' }]"></van-field>
				<van-field v-model="register.bank_number" name="bank_number" label="银行卡号" placeholder="请输入银行卡号" :rules="[{ required: true, message: '请输入银行卡号' }]"></van-field>
				<van-field v-model="register.current_address" name="current_address" label="现居地址" placeholder="请输入现居地址" :rules="[{ required: true, message: '请输入现居地址' }]"></van-field>
				<van-field v-model="register.emergency_name" name="emergency_name" label="紧急联系" placeholder="请输入紧急联系人" :rules="[{ required: true, message: '请输入紧急联系人' }]"></van-field>
				<van-field v-model="register.emergency_mobile" name="emergency_mobile" label="紧急手机" placeholder="请输入紧急联系人手机" :rules="[{ required: true, message: '请输入紧急联系人手机' }]"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="出生日期" placeholder="点击选择出生日期" @click="showPicker = true"></van-field>
				<van-field readonly clickable name="picker" :value="value" label="入职日期" placeholder="点击选择入职日期" @click="showPicker = true"></van-field>
				<van-field v-model="register.password" type="password" name="password" label="设置密码" placeholder="请输入系统登陆密码" :rules="[{ required: true, message: '请输入系统登陆密码' }]"></van-field>
				<div style="margin: 16px;">
					<van-button round block type="info" color="#c7000a" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</div>
		<div style="color: #666; font-size: 12px; text-align: center; line-height: 45px;">CopyRight 2019 - {{year}} {{$config.COMPANY}} 版权所有 <a href="https://beian.miit.gov.cn/">粤ICP备34235444号</a></div>
		<van-popup v-model="showPicker" position="bottom">
			<van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false"></van-picker>
		</van-popup>

	</el-container>
</template>

<style>
	html {
		max-width: 750px;
		width: 100%;
		margin: 0 auto;
	}
</style>

<script>
	import { mapMutations } from 'vuex';

	export default {
		computed: {
			year () {
				var d = new Date();
				return d.getFullYear();
			},
			is_mobile () {
				return this.$utils.isMobile();
			}
		},
		methods: {
			...mapMutations(['login']),
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message('请输入登录账户密码！');
					this.toLogin(this.register);
				});
			},
			async toLogin (data) {
				const res = await this.$http.post(this.$api.URI_LOGIN, data);
				const { redirect_uri } = this.$route.query;
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: '登录成功！正在进入系统...',
					onClose: () => {
						this.login(result);
						if (!redirect_uri) return this.$router.push({path: '/'});
						window.location.href = decodeURIComponent(redirect_uri);
					}
				});
			}
		},
		data() {
			return {
				type: true,
				showPicker: false,
				wochat_login: null,
				dingtalk_login: null,
				register: {},
				columns: ['杭州', '宁波', '温州', '嘉兴', '湖州']
			};
		},
		async created () {
			const { host } = window.location;
			const res = await this.$http.post(this.$api.URI_LOGIN, {action: 'getoa', host});
			const { code, result } = res.data;
			if (code) return false;
			if (result.wochat) {
				// 获取企业微信appid 和 agentid;
				this.wochat_login = result.wochat;
			}
			if (result.dingtalk) {
				// 获取钉钉cleint_id;
				this.dingtalk_login = result.dingtalk;
			}
			// console.log(this.$route)
			// var code = this.$route.query.code;
			// this.$message.error(code);
			// code && this.toLogin({ code });
		}
	}
</script>
